/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  float: left;
  margin-left: 80px;
}
div.sub1 div.sub2 {
  display: none !important;
}
.sub1 > .item.path {
  margin-right: -1px;
  border-right: 1px solid #f1f1f1;
}
div.sub1 .menu {
  font-size: 14px;
  color: #fb262d;
  padding: 20px 30px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  text-decoration: none;
  color: #d1040b;
}
div.sub1 a.path {
  margin-left: -1px;
  border-left: 1px solid #f1f1f1;
  text-shadow: 0 1px 0 #fff;
  background: url(/images/sub1.gif) repeat-x 0 50%;
  color: #555;
}
div.sub1 a.path:hover,
div.sub1 a.path:focus {
  color: #555;
}
/* LEVEL 2 */
div.sub2 {
  margin-left: 8px;
  margin-top: 10px;
  width: 200px;
  background: #f2f2f2;
  padding-top: 60px;
  padding-bottom: 10px;
}
.sub2 > .item {
  width: 100%;
}
div.sub2 .menu {
  display: block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding: 4px 0 4px 24px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 1px solid transparent;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  text-decoration: none;
  background: #fafafa;
  border-top-color: #f1f1f1;
  border-bottom-color: #f1f1f1;
}
div.sub2 a.path {
  text-decoration: none;
  background: #fafafa;
  border-top-color: #f1f1f1;
  border-bottom-color: #f1f1f1;
}
/* LEVEL 3 */
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 100%;
}
#find {
  display: block;
}
#root .frum .body .topi {
  width: 600px;
  /* wide tall */
}
#maincontent .frum .body .resp,
#maincontent .dlog .foot .resp {
  width: 80%;
}
#root .slim .body .topi {
  width: 304px;
  /* slim tall */
}
.area {
  width: 624px;
}
.area > .unit {
  margin-right: 4px;
  margin-left: 4px;
  width: 616px;
}
.area h2,
.area .foot {
  margin-right: 8px;
  margin-left: 8px;
}
.area .part,
.area > .grid table {
  margin-right: 8px;
  margin-left: 8px;
  width: 600px;
}
.area .tiny {
  width: 292px;
}
.area > .slim {
  width: 304px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 288px;
}
.area > .slim .tiny {
  margin-right: 8px;
  margin-left: 8px;
  width: 136px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 8px;
  margin-left: 8px;
}
.cb-layout2 .main {
  width: 616px;
}
.cb-layout2 .main > .unit {
  margin-right: 4px;
  margin-left: 4px;
  width: 608px;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 10px;
  margin-left: 10px;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 10px;
  margin-left: 10px;
  width: 588px;
}
.cb-layout2 .main .tiny {
  width: 284px;
}
.cb-layout2 .main > .slim {
  width: 300px;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 280px;
}
.cb-layout2 .main > .slim .tiny {
  margin-right: 10px;
  margin-left: 10px;
  width: 130px;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 10px;
  margin-left: 10px;
}
.side {
  width: 198px;
}
.side > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 198px;
}
.side h2,
.side .foot {
  margin-right: 8px;
  margin-left: 8px;
}
.side .part,
.side > .grid table {
  margin-right: 8px;
  margin-left: 8px;
  width: 182px;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 182px;
}
.side > .slim h2,
.side > .slim .foot,
.side > .slim .part,
.side > .slim.grid table {
  margin-right: 8px;
  margin-left: 8px;
}
.south {
  width: 240px;
}
.south > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 240px;
}
.south h2,
.south .foot {
  margin-right: 10px;
  margin-left: 10px;
}
.south .part,
.south > .grid table {
  margin-right: 10px;
  margin-left: 10px;
  width: 220px;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 220px;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 10px;
  margin-left: 10px;
}
.base {
  width: 832px;
}
.base > .unit {
  margin-right: 4px;
  margin-left: 4px;
  width: 824px;
}
.base h2,
.base .foot {
  margin-right: 14px;
  margin-left: 14px;
}
.base .part,
.base > .grid table {
  margin-right: 14px;
  margin-left: 14px;
  width: 796px;
}
.base .tiny {
  width: 384px;
}
.base > .slim {
  width: 408px;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 380px;
}
.base > .slim .tiny {
  margin-right: 14px;
  margin-left: 14px;
  width: 176px;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 14px;
  margin-left: 14px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout2 div.main {
  margin-left: 8px;
}
div.side {
  margin-top: 50px;
  margin-left: 10px;
}
.cb-layout2 div.side {
  margin-top: 31px;
  margin-left: 0;
  margin-right: 9px;
}
div.south {
  float: right;
  width: 240px;
}
div.base div.unit {
  min-height: 18em;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #fb262d;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #fb262d;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #ea040c;
}
.mese .same {
  background: none;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #fb353c;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #fb0d15;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/*# sourceMappingURL=./screen-large.css.map */